<template>
    <CHeader with-subheader>
        <CToggler
            in-header
            class='ml-3 d-lg-none'
            @click="$store.commit('toggleSidebarMobile')"
        />
        <CToggler
            in-header
            class='ml-3 d-md-down-none'
            @click="$store.commit('toggleSidebarDesktop')"
        />
        <CHeaderNav class='ml-auto'>
            <CHeaderNavItem class='px-3'>
                <button
                    @click="() => $store.commit('toggle', 'darkMode')"
                    class='c-header-nav-btn'
                >
                    <CIcon v-if='$store.state.darkMode' name='cil-sun' />
                    <CIcon v-else name='cil-moon' />
                </button>
            </CHeaderNavItem>
            <TheHeaderDropdownAccnt />
        </CHeaderNav>

        <CSubheader class='px-3'>
            <CBreadcrumbRouter class='border-0 mb-0' />
        </CSubheader>
    </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'


export default {
    name: 'TheHeader',
    components: {
        TheHeaderDropdownAccnt,
    },
}
</script>
