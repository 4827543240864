<template>
  <CDropdown
      inNav
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar mr-4">
          <img
              src="../../public/img/avatars/logout.png"
              class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>İşlemler</strong>
    </CDropdownHeader>
    <CDropdownItem @click="logout">

      <CIcon name="cil-lock-locked"/>
      Çıkış Yap

    </CDropdownItem>

  </CDropdown>
</template>

<script>
import {localStorageHelper} from '../helpers/localStorage';
export default {
  name: 'TheHeaderDropdownAccnt',
  data() {
    return {}
  },
  methods: {
    async logout() {
      localStorageHelper.UserJwt = null;
      await this.$router.replace('/login');
    }
  }
}
</script>
