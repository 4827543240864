export const nav: ContentToRender = [{
    _name: 'CSidebarNav',
    _children: [
        {
            _name: 'CSidebarNavItem',
            name: 'Anasayfa',
            to: '/dashboard',
            icon: 'cil-speedometer',
        },
        {
            _name: 'CSidebarNavItem',
            name: 'sevk sistemi',
            to: '/referral',
            icon: 'cil-share',
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['KULLANICI YÖNETİMİ'],
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Öğretmenler',
            to: '/ogretmenler',
            icon: 'cil-smile',
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Öğrenciler',
            to: '/ogrenciler',
            icon: 'cil-user',
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Öğrenci Kredisi',
            to: '/Öğrenci-Kredisi',
            icon: 'cil-user',
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['SİSTEM YÖNETİMİ'],
        },
        // {
        //   _name: 'CSidebarNavItem',
        //   name: 'İndirim Kodları',
        //   to: '/indirim-kodlari',
        //   icon: 'cil-barcode'
        // },
        {
            _name: 'CSidebarNavItem',
            name: 'Paketler',
            to: '/paketler',
            icon: 'cil-list-rich',
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Kategoriler',
            to: '/kategoriler',
            icon: 'cil-layers',
        },
        {
            _name: 'CSidebarNavItem',
            name: 'abonelikler',
            to: '/subscriptions',
            icon: 'cil-wallet',
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Legal Dökümanlar',
            to: '/legal-dokumanlar',
            icon: 'cil-description',
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Yöneticiler',
            to: '/yoneticiler',
            icon: 'cil-contact',
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Maaş bordrosu',
            to: '/Payroll',
            icon: 'cil-layers',
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Şikayetler',
            to: '/sikayetler',
            icon: 'cil-warning',
        },
        // {
        //     _name: 'CSidebarNavItem',
        //     name: 'Izgara Şablonlarını Gönder',
        //     to: '/SendGridTemplate',
        //     icon: 'cil-wallet',
        // },
        // {
        //     _name: 'CSidebarNavItem',
        //     name: 'E-posta',
        //     to: '/send-email',
        //     icon: 'cil-wallet',
        // },
        // {
        //   _name: 'CSidebarNavItem',
        //   name: 'Ödemeler',
        //   to: '/odemeler',
        //   icon: 'cil-money'
        // },
        //  {
        //    _name: 'CSidebarNavItem',
        //    name: 'İletişim Formları',
        //    to: '/iletisim-formlari',
        //    icon: 'cil-contact'
        //  },
        {
            _name: 'CSidebarNavItem',
            name: 'Ayarlar',
            to: '/ayarlar',
            icon: 'cil-settings',
        },
    ],
}]
