<template>
  <CFooter :fixed="false">
    <div>
        <a href="https://advancerenglish.com" target='_blank'>Advancer English</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
